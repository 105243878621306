import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import Logo from '../asset/inverika_only_logo.png';

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='transparent'>
        <Toolbar>
          <Link href="/">
            <img
              alt="logo"
              height={54}
              src={Logo}
            />
          </Link>
          <Typography variant="h2" sx={{ mt: 4, mb: 2, ml:2 }}>
            Inverika
          </Typography>
          {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              ml: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'galaxy-bt',
              fontWeight: 700,
              color: 'black',
              textDecoration: 'none',
            }}
          >
            INVERIKA
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            sx={{ mr: 2, alignContent: 'right' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}