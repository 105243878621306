
import ThemeProvider from './theme/theme_provider';
import { CssBaseline } from '@mui/material';

import LandingPage from './pages/landing_page';

function App() {
  return (
    <ThemeProvider>
        <CssBaseline />
        <LandingPage />
    </ThemeProvider>
  );
}

export default App;
