import NavBar from "../components/navbar";
import StatusComingSoon from "./status/coming_soon";

export default function LandingPage() {

  return(
  <div className="div">
    <NavBar />
    <StatusComingSoon />
  </div>
  );
}
